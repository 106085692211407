<template>
  <div id="parkingGroupDetail">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>停车场管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'parkingGroup' }"
        >联合停车场列表
      </el-breadcrumb-item>
      <el-breadcrumb-item>详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="basicsInfo">
      <h3>基础信息</h3>
      <ul class="info">
        <li>
          <span class="label">联合停车场名称：</span>
          <editable-input
            :value="info.groupName"
            :isRead="!buttonList.includes('parkingGroup-detail-info_groupName')"
            :params="{ parkingGroupId }"
            url="/parking/group/edit"
            attr="groupName"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">停车场数量：</span>
          <editable-input :value="info.parkingLotAmount" />
        </li>
        <li>
          <span class="label">入口数量：</span>
          <editable-input :value="info.entranceNum" />
        </li>
        <li>
          <span class="label">出口数量：</span>
          <editable-input :value="info.exitusNum" />
        </li>
        <li>
          <span class="label">总车位数：</span>
          <editable-input :value="info.totalLots" />
        </li>
        <li>
          <span class="label">剩余车位数：</span>
          <editable-input :value="info.idleLots" />
        </li>
        <li>
          <span class="label">产权车位：</span>
          <editable-input :value="info.property" />
        </li>
        <li>
          <span class="label">人防车位：</span>
          <editable-input :value="info.civilDefense" />
        </li>
      </ul>
    </div>
    <div class="tab-table">
      <el-tabs v-model="tabsName">
        <el-tab-pane
          label="出入口列表"
          name="parkingGroup-detail-passagewayTab"
          v-if="tabList.includes('parkingGroup-detail-passagewayTab')"
        >
          <passageway-list />
        </el-tab-pane>
        <el-tab-pane
          label="缴费记录"
          name="parkingGroup-detail-payRecordTab"
          v-if="tabList.includes('parkingGroup-detail-payRecordTab')"
        >
          <pay-record />
        </el-tab-pane>
        <el-tab-pane
          label="场内车辆"
          name="parkingGroup-detail-venueVehicleTab"
          v-if="tabList.includes('parkingGroup-detail-venueVehicleTab')"
        >
          <venue-vehicle />
        </el-tab-pane>
        <el-tab-pane
          label="商户管理"
          name="parkingGroup-detail-merchantTab"
          v-if="
            tabList.includes('parkingGroup-detail-merchantTab') &&
              !this.info.parkingParentId
          "
        >
          <merchant-tab />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    passagewayList: () => import("./tabs/passagewayList.vue"),
    payRecord: () => import("./tabs/payRecord.vue"),
    venueVehicle: () => import("./tabs/venueVehicle.vue"),
    merchantTab: () =>
      import("./tabs/merchant/merchant.vue"),
    editableInput: () => import("@/components/editable-input.vue"),
    editableSelect: () => import("@/components/editable-select.vue"),
  },
  data() {
    return {
      tabsName: "parkingLotList-detail-passagewayTab",
      info: {},
      parkingGroupId: "",
      BASE_URL: process.env.VUE_APP_BASE_URL,
    };
  },
  computed: {
    tabList() {
      let list = this.$store.state.controlAuthority.tabList;
      let tabsName_list = [
        "parkingGroup-detail-passagewayTab",
        "parkingGroup-detail-payRecordTab",
        "parkingGroup-detail-venueVehicleTab",
        "parkingGroup-detail-merchantTab",
      ];

      for (let item of tabsName_list) {
        if (list.includes(item)) {
          this.tabsName = item;
          break;
        }
      }
      return list;
    },
  },
  created() {
    this.parkingGroupId = this.$route.params.id;
    this.getInfo();
  },
  methods: {
    async getInfo() {
      try {
        let res = await this.$http.get(
          `/parking/group/${this.parkingGroupId}/detail`
        );
        if (res.code === 0) {
          this.info = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
  },
};
</script>

<style lang="scss">
#parkingGroupDetail {
  .invoice-operation {
    margin-bottom: 20px;
  }

  .advertisingImg {
    .el-image {
      height: 50px;
    }
  }

  .tab-table {
    padding: 15px;
  }
}
</style>
